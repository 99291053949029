import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { splitEvery, indexOf } from 'ramda';
import priceFloatConverter from 'utils/priceFloatConverter';
import CheckBox from '../../../components/UI/CheckBox';

import './optionPanel3.scss';

const OptionPanelControlType3 = ({ parentOption, parentOptionItem, option, childOption, onChange }) => {
  const { controlType, items = [] } = option || {};

  const firstChoiceMap = items?.map((i) => ({
    itemId: i?.id,
    selected: i?.firstChoice,
  }));

  const [isPaid] = useState(option?.items?.filter((item) => item.standardQuantity === 0));
  const [isFree] = useState(option?.items?.filter((item) => item.standardQuantity === 1));

  const paidItem = { ...option };
  paidItem.items = isPaid;

  const freeItem = { ...option };
  freeItem.items = isFree;

  const freeItemRowsDesktop = splitEvery(3, isFree || []);
  const paidItemRowsDesktop = splitEvery(3, isPaid || []);
  const freeItemRowsResponsive = splitEvery(2, isFree || []);
  const paidItemRowsResponsive = splitEvery(2, isPaid || []);

  const [firstChoiceMapData, setFirstChoiceMapData] = useState(firstChoiceMap);

  if (controlType !== 3) return null;

  const selecteds = firstChoiceMapData?.filter((i) => i?.selected);

  const limitReached = selecteds?.length >= option?.maximumOptioncount;

  return (
    <div className="option-panel-3">
      <div className="display-dekstop">
        {/* Free Garnitures Row START here DESKTOP */}
        {childOption && <div className="panel-title">{option?.title}</div>}
        {freeItemRowsDesktop?.map((row) => (
          <Row>
            {row?.map((item) => (
              <Col xs={6} lg={4} key={item?.id}>
                <OptionItemSelectable
                  firstChoiceMapData={firstChoiceMapData}
                  setFirstChoiceMapData={setFirstChoiceMapData}
                  key={item?.id}
                  item={item}
                  limitReached={limitReached}
                  parentOption={parentOption}
                  parentOptionItem={parentOptionItem}
                  childOption={childOption}
                  option={option}
                  onChange={onChange}
                />
              </Col>
            ))}
          </Row>
        ))}
        {/* Free Garnitures Row END here DESKTOP */}
        {/* Paid Garnitures Row START here DESKTOP */}
        {isPaid?.length > 0 && <div className="panel-title">Ekstra {option?.title}</div>}
        {paidItemRowsDesktop?.map((row) => (
          <Row>
            {row?.map((item) => (
              <Col xs={6} lg={4} key={item?.id}>
                <OptionItemSelectable
                  firstChoiceMapData={firstChoiceMapData}
                  setFirstChoiceMapData={setFirstChoiceMapData}
                  key={item?.id}
                  item={item}
                  limitReached={limitReached}
                  parentOption={parentOption}
                  parentOptionItem={parentOptionItem}
                  childOption={childOption}
                  option={option}
                  onChange={onChange}
                />
              </Col>
            ))}
          </Row>
        ))}
      </div>
      {/* Paid Garnitures Row END here DESKTOP */}
      <div className="display-responsive">
        {/* Free Garnitures Row START here RESPONSIVE */}
        {childOption && <div className="panel-title">{option?.title}</div>}
        {freeItemRowsResponsive?.map((row) => (
          <Row>
            {row?.map((item) => (
              <Col xs={6} lg={4} key={item?.id}>
                <OptionItemSelectable
                  firstChoiceMapData={firstChoiceMapData}
                  setFirstChoiceMapData={setFirstChoiceMapData}
                  key={item?.id}
                  item={item}
                  limitReached={limitReached}
                  parentOption={parentOption}
                  parentOptionItem={parentOptionItem}
                  childOption={childOption}
                  option={option}
                  onChange={onChange}
                />
              </Col>
            ))}
          </Row>
        ))}
        {/* Free Garnitures Row END here RESPONSIVE */}
        {/* Paid Garnitures Row START here RESPONSIVE */}
        {isPaid && <div className="panel-title">Ekstra {option?.title}</div>}
        {paidItemRowsResponsive?.map((row) => (
          <Row>
            {row?.map((item) => (
              <Col xs={6} lg={4} key={item?.id}>
                <OptionItemSelectable
                  firstChoiceMapData={firstChoiceMapData}
                  setFirstChoiceMapData={setFirstChoiceMapData}
                  key={item?.id}
                  item={item}
                  limitReached={limitReached}
                  parentOption={parentOption}
                  parentOptionItem={parentOptionItem}
                  childOption={childOption}
                  option={option}
                  onChange={onChange}
                />
              </Col>
            ))}
          </Row>
        ))}
        {/* Paid Garnitures Row END here RESPONSIVE */}
      </div>
    </div>
  );
};

OptionPanelControlType3.propTypes = {
  parentOption: PropTypes.object,
  parentOptionItem: PropTypes.object,
  option: PropTypes.object,
  childOption: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionPanelControlType3;

const OptionItemSelectable = ({
  parentOption,
  parentOptionItem,
  option,
  childOption,
  onChange,
  item,
  firstChoiceMapData,
  setFirstChoiceMapData,
  limitReached,
}) => {
  const { firstChoice } = item;
  const [checked, setChecked] = useState(firstChoice);

  const handleCheckboxValueChange = () => {
    const data = firstChoiceMapData;

    const indexOfId = indexOf(
      item.id,
      data.map((i) => i.itemId),
    );
    const { pricePerProductOption } = item;
    const nextValue = !checked;
    onChange(parentOption, parentOptionItem, option, item, childOption, nextValue ? 1 : 0, pricePerProductOption);

    setChecked(nextValue);

    data[indexOfId].selected = nextValue;

    setFirstChoiceMapData(data);
  };

  return (
    <div
      onClick={() => {
        if (limitReached && !checked) return;
        handleCheckboxValueChange();
      }}
    >
      <CheckBox disabled={limitReached && !checked} rounded checked={checked} onPress={handleCheckboxValueChange}>
        <div className="d-flex flex-column">
          <div>{item.title}</div>
          <div className="price">
            {item.pricePerProductOption ? '+' : ''}
            {`${item.pricePerProductOption ? priceFloatConverter(item.pricePerProductOption) : ''}`}
          </div>
        </div>
      </CheckBox>
    </div>
  );
};

OptionItemSelectable.propTypes = {
  firstChoiceMapData: PropTypes.array,
  setFirstChoiceMapData: PropTypes.func,
  parentOption: PropTypes.object,
  parentOptionItem: PropTypes.object,
  option: PropTypes.object,
  item: PropTypes.object,
  childOption: PropTypes.bool,
  limitReached: PropTypes.bool,
  onChange: PropTypes.func,
};
