const priceFloatConverter = (item) => {
  const currencySymbol = '₺';
  const currencyName = 'Türk lirası';
  const formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    currencyDisplay: 'name',
    minimumFractionDigits: 2,
  }).format(item);

  return formattedOutput.replace(currencySymbol, '').replace(currencyName, 'TL');
};

export default priceFloatConverter;
