import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './checkBox.scss';

function Checkbox(props) {
  const {
    id,
    value,
    invalid,
    checked,
    rounded,
    onPress,
    inline,
    children,
    className,
    disabled,
    error,
    errorClassName,
  } = props;

  return (
    <label className={`checkbox checkbox-background ${inline && 'inline'} ${className}`}>
      <span className="input">
        <input
          id={id}
          type="checkbox"
          name="checkbox"
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={() => onPress()}
        />
        <div className={`frame ${rounded && 'rounded'} ${disabled && 'disabled'}`}>
          <i className="icon-Check" />
        </div>
        <div className={`checkbox-label ${invalid && 'invalid-label'}`}>{children}</div>
      </span>
      {error && <span className={`error ${errorClassName}`}>{error}</span>}
    </label>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  invalid: PropTypes.bool,
  checked: PropTypes.bool,
  rounded: PropTypes.bool,
  onPress: PropTypes.func,
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  errorClassName: PropTypes.string,
};

export default memo(Checkbox);
